import { render, staticRenderFns } from "./EventForm.vue?vue&type=template&id=73b559a8&scoped=true"
import script from "./EventForm.vue?vue&type=script&lang=js"
export * from "./EventForm.vue?vue&type=script&lang=js"
import style0 from "./EventForm.vue?vue&type=style&index=0&id=73b559a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73b559a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFlex,VForm,VLayout,VSwitch,VTextarea})
