<template>
  <CRSidebarDialog
    v-model="isActive"
    persistent
    @input="closeHandlerWrapper(false)"
  >
    <template #title>{{ isModeAdd ? 'Add' : 'Edit' }} Event</template>
    <div class="event-sidebar" :title="null">
      <v-form ref="eventForm">
        <div class="event-sidebar--event-form">
          <v-flex>
            <v-switch
              v-model="event.isLive"
              class="ma-0 pa-0"
              color="primary"
              :disabled="isModeView"
            >
              <template #label>
                <span
                  :style="{
                    color: event.isLive && !isModeView ? $cr.theme.primary : '',
                  }"
                >
                  {{ event.isLive ? 'Live' : 'Inactive' }}
                </span>
              </template>
            </v-switch>
          </v-flex>
          <v-flex>
            <CRInput
              id="event-form-event-name"
              v-model="event.title"
              label="Event Name"
              placeholder="Event Name"
              type="text"
              :disabled="isModeView"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Event Name is Required',
                  error: 'Event Name is Required',
                }),
              ]"
            />
          </v-flex>
          <v-flex>
            <CRInput
              v-if="!event.eventAddress.name"
              id="event-form-text-address"
              label="Address of Event"
              placeholder="Type to search"
              :disabled="isModeView"
              :rules="[
                isRequired(true, () => validateAddress(event.eventAddress), {
                  req: 'Address Is Required',
                  error: 'Address Is Required',
                }),
              ]"
              type="address"
              :use-formatted-detail="true"
              identifier="event_location"
              @place-selected="(val) => (event.eventAddress = val.place)"
            />
            <CRInput
              v-if="event.eventAddress && event.eventAddress.name"
              id="event-form-event-address"
              label="Address of Event"
              type="text"
              clear-icon="replay"
              :clearable="true"
              :readonly="true"
              :disabled="isModeView"
              :rules="[
                isRequired(true, () => validateAddress(event.eventAddress), {
                  req: 'Address Is Required',
                  error: 'Address Is Required',
                }),
              ]"
              :value="op(event, 'eventAddress/name') || ''"
              @click:clear="event.eventAddress = {}"
            />
          </v-flex>
          <v-flex>
            <v-layout padded align-center>
              <v-flex>
                <CRInput
                  id="event-form-start-date"
                  v-model="event.startDate"
                  label="Start Date"
                  type="date"
                  :disabled="isModeView"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'Start Date is Required',
                      error: 'Start Date is Required',
                    }),
                  ]"
                />
              </v-flex>
              <v-flex>
                <CRInput
                  id="event-form-end-date"
                  v-model="event.endDate"
                  label="End Date"
                  type="date"
                  :disabled="isModeView"
                  :rules="[
                    isRequired(true, isNotEmpty, {
                      req: 'End Date is Required',
                      error: 'End Date is Required',
                    }),
                    (val) =>
                      val < event.startDate
                        ? 'End Date must be after Start Date'
                        : true,
                  ]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <CRSelect
              id="eventSeverity"
              v-model="event.severityTypeKey"
              label="Severity"
              placeholder="Severity"
              :items="severityItems"
              :disabled="isModeView"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Event Severity is Required',
                  error: 'Event Severity is Required',
                }),
              ]"
            />
          </v-flex>
          <v-flex>
            <label for="eventComments">Comments</label>
            <v-textarea
              id="eventComments"
              v-model="event.comments"
              flat
              solo
              :disabled="isModeView"
            />
          </v-flex>
          <v-flex>
            <CRSelect
              id="select-vehicle-availability"
              v-model="event.marketVehicleAvailability"
              label="Vehicle Availability for this Event"
              flat
              :items="marketVehicleAvailabilityTypes"
              item-text="label"
              item-value="key"
              :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
              :disabled="isModeView"
              :rules="[
                (val) =>
                  !event.marketVehicleAvailability
                    ? 'Vehicle Availability is Required'
                    : true,
              ]"
            />
          </v-flex>
          <div
            v-if="
              event.isSoldOut ||
              event.marketVehicleAvailability === 'sold-out' ||
              event.marketVehicleAvailability === 'local-only'
            "
          >
            <v-flex>
              <CRSelect
                id="select-market"
                v-model="event.market"
                label="Market"
                flat
                placeholder="Select Market"
                :items="markets"
                item-text="marketName"
                item-value="marketId"
                :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
                :disabled="isModeView"
                :multiple="isModeAdd ? true : false"
                :rules="[
                  (val) =>
                    event.marketVehicleAvailability !== 'no-impact' &&
                    (!val || val.length === 0)
                      ? 'Market is Required'
                      : true,
                ]"
              />
            </v-flex>
            <v-flex>
              <CRSelect
                id="event-form-vehicle-type"
                v-model="event.vehicleType"
                label="Vehicle Type"
                placeholder="Select vehicle type"
                item-value="id"
                item-text="label"
                :items="vehicleTypes"
                :disabled="isModeView"
                :multiple="isModeAdd ? true : false"
                :rules="[
                  (val) =>
                    event.marketVehicleAvailability !== 'no-impact' &&
                    (!val || val.length === 0)
                      ? 'Vehicle Type is Required'
                      : true,
                ]"
              />
            </v-flex>
          </div>
        </div>
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <div v-if="isModeAdd">
          <CRButton
            id="event-sidebar-save-btn"
            class="event-sidebar--action-btn"
            color="primary"
            @click="addEvents"
          >
            Save
          </CRButton>
        </div>
        <div v-else-if="isModeView">
          <CRButton
            id="event-sidebar-edit-btn"
            class="event-sidebar--action-btn"
            color="primary"
            @click="editHandler"
          >
            Edit
          </CRButton>
        </div>
        <div v-else-if="isModeEdit">
          <div class="cr-sidebar-dialog--button-spacer"></div>
          <CRButton
            id="event-sidebar-delete-btn"
            class="event-sidebar--delete-btn"
            text-color="red"
            @click="deleteHandler"
          >
            Delete
          </CRButton>
          <CRButton
            id="event-sidebar-save-btn"
            class="event-sidebar--action-btn"
            color="primary"
            @click="saveEvent"
          >
            Save
          </CRButton>
        </div>
      </v-form>
    </div>
  </CRSidebarDialog>
</template>

<script>
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { isRequired, isNotEmpty, validateAddress } from '@/utils/validators'
import op from 'simple-object-path'
import events from '@/services/events'
import markets from '@/services/markets'
import { getOffsetDate } from '@/utils/time'
import { deepClone } from '@/utils/deepClone'
import { eventTemplate } from '@/utils/eventTemplate'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    mode: {
      type: String,
      default: 'view',
    },
    active: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isRequired,
      isNotEmpty,
      validateAddress,
      op,
      valid: true,
      formSubmitAttempt: false,
      saveInitiated: false,
      editingAddress: undefined,
      event: deepClone(eventTemplate()),
      severityItems: [
        {
          id: 'low',
          text: 'Low',
          value: 'low',
        },
        {
          text: 'Medium',
          value: 'medium',
        },
        {
          text: 'High',
          value: 'high',
        },
        {
          text: 'Extreme',
          value: 'extreme',
        },
      ],
      markets: [],
      vehicleTypes: [],
      isActive: false,
      internalMode: 'add',
      marketVehicleAvailabilityTypes: [],
    }
  },
  computed: {
    isModeAdd() {
      return this.internalMode === 'add'
    },
    isModeView() {
      return this.internalMode === 'view'
    },
    isModeEdit() {
      return this.internalMode === 'edit'
    },
  },
  watch: {
    mode(value) {
      if (!this.isModeAdd) {
        this.resetForm()
      }

      this.internalMode = value
    },
    active(value) {
      this.isActive = value
      this.internalMode = this.mode
      if (this.isActive && !this.isModeAdd) {
        this.event = JSON.parse(JSON.stringify(this.formData))
        this.event.eventAddress.name = this.event.eventAddress?.addressName
        this.event.market = this.event.market?.marketId
        this.event.vehicleType = this.event.vehicleType?.id
      }
    },
  },
  mounted() {
    this.isActive = this.active
    this.internalMode = this.mode
    this.getMarkets()
    this.getVehicleTypes()
    this.getMarketVehicleAvailabilityTypes()
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    getOffsetDate,
    async getMarkets() {
      this.markets = await markets
        .tableView({
          pageSize: -1,
        })
        .then((data) => data.data.resultList)
    },
    async getVehicleTypes() {
      this.vehicleTypes = await this.$store
        .dispatch('types/getVehicleTypes')
        .then((data) => data.data.resultList)
    },
    async getMarketVehicleAvailabilityTypes() {
      this.marketVehicleAvailabilityTypes = (
        await this.$store.dispatch('types/getMarketVehicleAvailabilityTypes')
      ).data
    },
    resetForm() {
      this.event = deepClone(eventTemplate())
      this.formSubmitAttempt = false
      this.saveInitiated = false
    },
    getFormattedDatetime(datetime) {
      return DateTime.fromISO(datetime, { zone: 'utc' }).toFormat(
        'yyyy-MM-dd HH:mm'
      )
    },
    closeHandlerWrapper(refreshStateAfterAction) {
      this.resetForm()
      this.closeHandler(refreshStateAfterAction)
    },
    async addEvents() {
      event.preventDefault()
      this.formSubmitAttempt = true
      this.valid = this.$refs.eventForm.validate()
      if (!this.valid) {
        return
      }
      if (this.saveInitiated) {
        return
      }
      this.saveInitiated = true

      delete this.event.severityTypeLabel
      this.event.startDate = DateTime.fromISO(this.event.startDate, {
        zone: this.event.eventAddress.timeZone,
      }).toISO()
      this.event.endDate = DateTime.fromISO(this.event.endDate, {
        zone: this.event.eventAddress.timeZone,
      }).toISO()
      this.event.createdOn = new Date()

      this.event.isSoldOut = this.event.marketVehicleAvailability === 'sold-out'

      const params = {
        body: this.createMultiselectPayloads(),
      }

      await events.saveEvents(params)
      this.$store.dispatch('app/showAlert', {
        message: 'Event saved.',
        type: 'success',
      })
      EventBus.$emit(
        'reload-pricing-calendar',
        this.event.startDate,
        this.event.endDate,
        'event'
      )
      this.clearForm()
    },
    createMultiselectPayloads() {
      if (this.event.marketVehicleAvailability !== 'no-impact') {
        let payloads = []
        const markets = this.event?.market
        const vehicleTypes = this.event?.vehicleType
        markets.map((market) =>
          vehicleTypes.map((vehicleType) => {
            let payload = deepClone(this.event)
            payload.marketId = market
            payload.vehicleTypeId = vehicleType
            payloads.push(payload)
          })
        )
        return payloads
      } else {
        return [this.event]
      }
    },
    async saveEvent() {
      event.preventDefault()
      this.formSubmitAttempt = true
      this.valid = this.$refs.eventForm.validate()
      if (!this.valid) {
        return
      }
      // TODO: Use VueX for saving/loading states
      if (this.saveInitiated) {
        // prevent multiple clicks
        return
      }
      this.saveInitiated = true
      const { eventId } = this.event
      delete this.event.severityTypeLabel
      this.event.startDate = DateTime.fromISO(this.event.startDate, {
        zone: this.event.eventAddress.timeZone,
      }).toISO()
      this.event.endDate = DateTime.fromISO(this.event.endDate, {
        zone: this.event.eventAddress.timeZone,
      }).toISO()
      const params = {
        editMode: this.isModeEdit,
        eventId,
        body: this.event,
      }
      if (this.event.marketVehicleAvailability !== 'no-impact') {
        params.body.marketId = this.event.market
        params.body.vehicleTypeId = this.event.vehicleType
      } else {
        params.body.marketId = null
        params.body.vehicleTypeId = null
      }

      params.body.isSoldOut =
        this.event.marketVehicleAvailability === 'sold-out'

      this.event.createdOn = new Date()
      await events.saveEvent(params)
      this.$store.dispatch('app/showAlert', {
        message: 'Event saved.',
        type: 'success',
      })
      EventBus.$emit(
        'reload-pricing-calendar',
        this.event.startDate,
        this.event.endDate,
        'event',
        eventId
      )
      this.clearForm()
    },
    clearForm() {
      this.closeHandlerWrapper(true)
      this.event = deepClone(eventTemplate())
      this.formSubmitAttempt = false
      this.saveInitiated = false
      this.internalMode = 'view'
    },
    editHandler(event) {
      event.preventDefault()
      this.internalMode = 'edit'
    },
    async deleteHandler(event) {
      event.preventDefault()
      try {
        await events.deleteEvent(this.event?.eventId)
        this.showAlert({
          message: 'Event Deleted.',
        })
        this.closeHandlerWrapper(true)
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Unable to delete Event.',
        })
        this.closeHandlerWrapper()
      }
      this.resetForm()
    },
  },
}
</script>

<style lang="scss" scoped>
.event-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  &--event-base {
    border-bottom: 1px solid $dispatch-gray;
    margin-bottom: 20px;
  }

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--event-form {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.form-error {
  color: $red;
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.cr-modal {
  .v-text-field__details {
    display: none;
  }
}
</style>
