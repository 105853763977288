<template>
  <v-card-title primary-title></v-card-title>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  mounted() {},
}
</script>
