// Define the type for the address within the event
interface EventAddress {
  addressName?: string;
  city?: string;
  lat?: number;
  lng?: number;
  postalCode?: string;
  state?: string;
  street1?: string;
  timeZone?: string;
  name?: string;
}

// Define the type for the event itself
interface EventTemplate {
  active: boolean;
  comments?: string;
  title?: string;
  market?: any; // Use a more specific type if possible
  startDate?: Date;
  endDate?: Date;
  createdOn?: Date;
  eventAddress: EventAddress;
  severityTypeKey?: string;
  severityTypeLabel?: string;
  isSoldOut: boolean;
  marketVehicleAvailability?: any; // Use a more specific type if possible
  isLive: boolean;
}

export const eventTemplate = (): EventTemplate => {
  return {
    active: true,
    comments: undefined,
    title: undefined,
    market: null,
    startDate: undefined,
    endDate: undefined,
    createdOn: undefined,
    eventAddress: {
      addressName: undefined,
      city: undefined,
      lat: undefined,
      lng: undefined,
      postalCode: undefined,
      state: undefined,
      street1: undefined,
      timeZone: undefined,
    },
    severityTypeKey: undefined,
    severityTypeLabel: undefined,
    isSoldOut: false,
    marketVehicleAvailability: undefined,
    isLive: true,
  };
}
