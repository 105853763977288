import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Event } from '@/models/dto/Event'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getEvents(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Event>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/events?${query}`
    return httpService.get(url)
  },
  eventByLocation(params: { lat: number, lng: number, radius: number, pickupDate: Date, dropOffDate: Date}): Promise<AxiosResponse<ApiResult>> {
    const { lat, lng, radius, pickupDate, dropOffDate } = params
    const query = [
      `lat=${lat}`,
      `lng=${lng}`,
      `radius=${radius}`,
      `pickupDate=${pickupDate}`,
      `dropOffDate=${dropOffDate}`,
    ]
    const host = baseUrl()
    const url = `https://${host}/events/search?${query.join('&')}`
    return httpService.get(url)
  },
  saveEvent(params: { body: Event, eventId: number, editMode: boolean}): Promise<AxiosResponse<ApiResult>> {
    const { body, eventId, editMode } = params
    const host = baseUrl()
    if (editMode) {
      const url = `https://${host}/v2/events/${eventId}`
      return httpService.put(url, body)
    }
    const url = `https://${host}/v2/events`
    return httpService.post(url, body)
  },
  saveEvents(params: { body: any }): Promise<AxiosResponse<ApiResult>> {
    const { body } = params
    const host = baseUrl()
    const url = `https://${host}/v2/events/batch`
    return httpService.post(url, body)
  },
  deleteEvent(eventId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/v2/events/${eventId}`
    return httpService.delete(url)
  },
}
