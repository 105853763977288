<template>
  <div>
    <EventForm
      :active="active"
      :form-data="formData"
      :close-handler="closeHandler"
      :mode="mode"
    />
    <BaseTableWrapper :table-init="tableProps">
      <h1 class="page-header">Events</h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import Vue from 'vue'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import EventViewDetail from '@/components/EventViewDetail.vue'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { datePredefined } from '@/utils/predefined'
import EventForm from '@/components/EventForm.vue'
import EventActions from '@/components/EventActions.vue'
import MarketVehicleAvailabilityFilterLocalOnly from '@/components/MarketVehicleAvailabilityFilterLocalOnly.vue'
import MarketVehicleAvailabilityFilterSoldOut from '@/components/MarketVehicleAvailabilityFilterSoldOut.vue'
import events from '@/services/events'

export default {
  components: {
    BaseTableWrapper,
    EventForm,
  },
  metaInfo() {
    return {
      title: 'Events',
    }
  },
  data() {
    return {
      active: false,
      mode: 'view',
      formData: null,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      user: {},
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        id: 'event-list',
        detail: EventViewDetail,
        detailKeyId: 'eventId',
        addNewEnabled: true,
        enableColumnConfig: false,
        areStatusFiltersShowing: false,
        addNewHandler: this.addNew,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        collection: 'Events',
        forceRefresh: 0,

        enableExport: false,
        enableSavedViews: true,
        total: 0,
        list: [],
        columns: [
          {
            _t_id: '089d3f8b',
            prop: '/',
            text: 'Actions',
            component: this.createComponent(EventActions, this.viewAndEdit),
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
          },
          {
            _t_id: '18f7c7ee',
            prop: 'eventId',
            text: 'ID',
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            filterType: 'eq',
            sortProp: 'id',
            defaultSort: true,
          },
          {
            _t_id: '7b6f15ee',
            prop: 'title',
            text: 'Event Title',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: '2fd51eee',
            prop: 'startDate',
            text: 'Start Date',
            computedText: (item) =>
              DateTime.fromISO(item).toUTC().toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            detail: false,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'a6d352fa',
            prop: 'endDate',
            text: 'End Date',
            computedText: (item) =>
              DateTime.fromISO(item).toUTC().toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            detail: false,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'e9b76c24',
            prop: 'severityTypeLabel',
            filterProp: 'severityType.label',
            sortProp: 'severityType.label',
            text: 'Severity',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: '1bd90b5a',
            prop: ['eventAddress/city', 'eventAddress/state'],
            filterProp: ['address/city', 'address/state'],
            text: 'Address',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: 'f3009b72',
            prop: 'market/marketName',
            sortProp: 'market',
            text: 'Market',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: 'e60f7a40',
            prop: 'vehicleType/label',
            sortProp: 'vehicleType',
            text: 'Vehicle Type',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: '0292a863',
            prop: 'marketVehicleAvailability',
            text: 'Sold Out',
            computedText: (item) => {
              return item === 'sold-out' ? 'Yes' : 'No'
            },
            sort: false,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'eq',
            customFilterTabDisplay: (val) => {
              return val === 'sold-out' ? 'Yes' : 'No'
            },
            predefined: [
              {
                controlType: 'default-repeat',
                refreshOnSelect: true,
                controls: [
                  {
                    id: '78fb4781',
                    filterType: 'contains',
                    component: MarketVehicleAvailabilityFilterSoldOut,
                  },
                ],
              },
            ],
          },
          {
            _t_id: '04b72974',
            prop: 'marketVehicleAvailability',
            text: 'OTR Sold Out',
            computedText: (item) => (item === 'local-only' ? 'Yes' : 'No'),
            sort: false,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'eq',
            predefined: [
              {
                controlType: 'default-repeat',
                refreshOnSelect: true,
                controls: [
                  {
                    id: 'd413244d',
                    filterType: 'contains',
                    component: MarketVehicleAvailabilityFilterLocalOnly,
                  },
                ],
              },
            ],
          },
          {
            _t_id: 'd8b5fc7e',
            prop: 'isLive',
            text: 'Live',
            computedText: (item) => (item ? 'Yes' : 'No'),
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            filterType: 'eq',
            predefined: [
              {
                text: 'Yes',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 1,
              },
              {
                text: 'No',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 0,
              },
            ],
          },
        ],
        action: (params) => events.getEvents(params),
      },
    }
  },
  methods: {
    addNew() {
      this.active = true
      this.mode = 'add'
    },
    viewAndEdit(data) {
      this.active = true
      this.formData = data
      this.mode = 'view'
    },
    closeHandler(refreshQuery) {
      if (refreshQuery) {
        this.tableProps.forceRefresh = 1 + Math.random()
      }
      this.active = false
    },
    createComponent(component, handler) {
      return Vue.component('WrappedActions', {
        props: {
          row: {
            type: Object,
            default: () => ({}),
          },
          handleAction: {
            type: Function,
            default: () => null,
          },
          rowProps: {
            type: Object,
            default: () => ({}),
          },
        },
        render(createElement) {
          return createElement(component, {
            props: {
              row: this.row,
              handleAction: this.handleAction,
              rowProps: this.rowProps,
              handler: () => {
                handler(this.row)
              },
            },
          })
        },
      })
    },
  },
}
</script>
