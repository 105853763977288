var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRSidebarDialog',{attrs:{"persistent":""},on:{"input":function($event){return _vm.closeHandlerWrapper(false)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.isModeAdd ? 'Add' : 'Edit')+" Event")]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',{staticClass:"event-sidebar",attrs:{"title":null}},[_c('v-form',{ref:"eventForm"},[_c('div',{staticClass:"event-sidebar--event-form"},[_c('v-flex',[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"primary","disabled":_vm.isModeView},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{style:({
                  color: _vm.event.isLive && !_vm.isModeView ? _vm.$cr.theme.primary : '',
                })},[_vm._v(" "+_vm._s(_vm.event.isLive ? 'Live' : 'Inactive')+" ")])]},proxy:true}]),model:{value:(_vm.event.isLive),callback:function ($$v) {_vm.$set(_vm.event, "isLive", $$v)},expression:"event.isLive"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"event-form-event-name","label":"Event Name","placeholder":"Event Name","type":"text","disabled":_vm.isModeView,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Event Name is Required',
                error: 'Event Name is Required',
              }),
            ]},model:{value:(_vm.event.title),callback:function ($$v) {_vm.$set(_vm.event, "title", $$v)},expression:"event.title"}})],1),_c('v-flex',[(!_vm.event.eventAddress.name)?_c('CRInput',{attrs:{"id":"event-form-text-address","label":"Address of Event","placeholder":"Type to search","disabled":_vm.isModeView,"rules":[
              _vm.isRequired(true, () => _vm.validateAddress(_vm.event.eventAddress), {
                req: 'Address Is Required',
                error: 'Address Is Required',
              }),
            ],"type":"address","use-formatted-detail":true,"identifier":"event_location"},on:{"place-selected":(val) => (_vm.event.eventAddress = val.place)}}):_vm._e(),(_vm.event.eventAddress && _vm.event.eventAddress.name)?_c('CRInput',{attrs:{"id":"event-form-event-address","label":"Address of Event","type":"text","clear-icon":"replay","clearable":true,"readonly":true,"disabled":_vm.isModeView,"rules":[
              _vm.isRequired(true, () => _vm.validateAddress(_vm.event.eventAddress), {
                req: 'Address Is Required',
                error: 'Address Is Required',
              }),
            ],"value":_vm.op(_vm.event, 'eventAddress/name') || ''},on:{"click:clear":function($event){_vm.event.eventAddress = {}}}}):_vm._e()],1),_c('v-flex',[_c('v-layout',{attrs:{"padded":"","align-center":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":"event-form-start-date","label":"Start Date","type":"date","disabled":_vm.isModeView,"rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Start Date is Required',
                    error: 'Start Date is Required',
                  }),
                ]},model:{value:(_vm.event.startDate),callback:function ($$v) {_vm.$set(_vm.event, "startDate", $$v)},expression:"event.startDate"}})],1),_c('v-flex',[_c('CRInput',{attrs:{"id":"event-form-end-date","label":"End Date","type":"date","disabled":_vm.isModeView,"rules":[
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'End Date is Required',
                    error: 'End Date is Required',
                  }),
                  (val) =>
                    val < _vm.event.startDate
                      ? 'End Date must be after Start Date'
                      : true,
                ]},model:{value:(_vm.event.endDate),callback:function ($$v) {_vm.$set(_vm.event, "endDate", $$v)},expression:"event.endDate"}})],1)],1)],1),_c('v-flex',[_c('CRSelect',{attrs:{"id":"eventSeverity","label":"Severity","placeholder":"Severity","items":_vm.severityItems,"disabled":_vm.isModeView,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Event Severity is Required',
                error: 'Event Severity is Required',
              }),
            ]},model:{value:(_vm.event.severityTypeKey),callback:function ($$v) {_vm.$set(_vm.event, "severityTypeKey", $$v)},expression:"event.severityTypeKey"}})],1),_c('v-flex',[_c('label',{attrs:{"for":"eventComments"}},[_vm._v("Comments")]),_c('v-textarea',{attrs:{"id":"eventComments","flat":"","solo":"","disabled":_vm.isModeView},model:{value:(_vm.event.comments),callback:function ($$v) {_vm.$set(_vm.event, "comments", $$v)},expression:"event.comments"}})],1),_c('v-flex',[_c('CRSelect',{attrs:{"id":"select-vehicle-availability","label":"Vehicle Availability for this Event","flat":"","items":_vm.marketVehicleAvailabilityTypes,"item-text":"label","item-value":"key","menu-props":{ overflowY: true, maxHeight: '200px !important' },"disabled":_vm.isModeView,"rules":[
              (val) =>
                !_vm.event.marketVehicleAvailability
                  ? 'Vehicle Availability is Required'
                  : true,
            ]},model:{value:(_vm.event.marketVehicleAvailability),callback:function ($$v) {_vm.$set(_vm.event, "marketVehicleAvailability", $$v)},expression:"event.marketVehicleAvailability"}})],1),(
            _vm.event.isSoldOut ||
            _vm.event.marketVehicleAvailability === 'sold-out' ||
            _vm.event.marketVehicleAvailability === 'local-only'
          )?_c('div',[_c('v-flex',[_c('CRSelect',{attrs:{"id":"select-market","label":"Market","flat":"","placeholder":"Select Market","items":_vm.markets,"item-text":"marketName","item-value":"marketId","menu-props":{ overflowY: true, maxHeight: '200px !important' },"disabled":_vm.isModeView,"multiple":_vm.isModeAdd ? true : false,"rules":[
                (val) =>
                  _vm.event.marketVehicleAvailability !== 'no-impact' &&
                  (!val || val.length === 0)
                    ? 'Market is Required'
                    : true,
              ]},model:{value:(_vm.event.market),callback:function ($$v) {_vm.$set(_vm.event, "market", $$v)},expression:"event.market"}})],1),_c('v-flex',[_c('CRSelect',{attrs:{"id":"event-form-vehicle-type","label":"Vehicle Type","placeholder":"Select vehicle type","item-value":"id","item-text":"label","items":_vm.vehicleTypes,"disabled":_vm.isModeView,"multiple":_vm.isModeAdd ? true : false,"rules":[
                (val) =>
                  _vm.event.marketVehicleAvailability !== 'no-impact' &&
                  (!val || val.length === 0)
                    ? 'Vehicle Type is Required'
                    : true,
              ]},model:{value:(_vm.event.vehicleType),callback:function ($$v) {_vm.$set(_vm.event, "vehicleType", $$v)},expression:"event.vehicleType"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),(_vm.isModeAdd)?_c('div',[_c('CRButton',{staticClass:"event-sidebar--action-btn",attrs:{"id":"event-sidebar-save-btn","color":"primary"},on:{"click":_vm.addEvents}},[_vm._v(" Save ")])],1):(_vm.isModeView)?_c('div',[_c('CRButton',{staticClass:"event-sidebar--action-btn",attrs:{"id":"event-sidebar-edit-btn","color":"primary"},on:{"click":_vm.editHandler}},[_vm._v(" Edit ")])],1):(_vm.isModeEdit)?_c('div',[_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('CRButton',{staticClass:"event-sidebar--delete-btn",attrs:{"id":"event-sidebar-delete-btn","text-color":"red"},on:{"click":_vm.deleteHandler}},[_vm._v(" Delete ")]),_c('CRButton',{staticClass:"event-sidebar--action-btn",attrs:{"id":"event-sidebar-save-btn","color":"primary"},on:{"click":_vm.saveEvent}},[_vm._v(" Save ")])],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }