<template>
  <CRSelect
    :id="`${id}-vehicle-market-availability-filter-sold-out-select`"
    v-model="filterOptionSelection"
    :items="filterOptions"
    chips
    flat
    hide-details
    item-text="text"
    item-value="filter"
    :multiple="false"
    solo
    @input="setFilter"
  />
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filterOptions: [
        {
          text: 'Yes',
          filter: {
            column: {
              _t_id: '0292a863',
              prop: 'marketVehicleAvailability',
              filterType: 'eq',
            },
            value: 'sold-out',
          },
        },
        {
          text: 'No',
          filter: {
            column: {
              _t_id: '0292a863',
              prop: 'marketVehicleAvailability',
              filterType: 'neq',
            },
            value: 'sold-out',
          },
        },
      ],
      filterOptionSelection: '',
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      for (const filterOption of this.filterOptions) {
        this.filters.remove(filterOption.filter)
      }

      const self = this
      const newFilter = this.filterOptions.find((filter) => {
        return (
          filter.filter.column.filterType ===
            self.filterOptionSelection.column.filterType &&
          filter.filter.column._t_id ===
            self.filterOptionSelection.column._t_id &&
          filter.filter.column.prop ===
            self.filterOptionSelection.column.prop &&
          filter.filter.value === self.filterOptionSelection.value
        )
      })

      if (newFilter) {
        const parent = this.filters.createParent('and')
        this.filters.add(parent, newFilter.filter)
      }

      this.addFilter()
    },
  },
}
</script>
